import styled from 'styled-components';
import { theme } from '@theme/theme';

export const Bar = styled.div`
  display: flex;
  align-items: center;
  height: 75px;
  background: transparent;
  position: fixed;
  top: 0;
  z-index: 5;
`;

export const LogoSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 50px;
  background: ${theme.colors.halfDarkPurple};

  width: 350px;
  height: 100%;
`;
