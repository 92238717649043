import { colors } from './colors';

export const theme = {
  spaces: [
    '0px',
    '4px',
    '8px',
    '16px',
    '24px',
    '32px',
    '64px',
    '100px',
    '135px',
    '170px',
  ],
  sizes: [
    '1.4rem', // 0
    '1.6rem',
    '1.8rem',
    '2.0rem', // 3
    '2.2rem',
    '2.4rem',
    '2.8rem', // 6
    '3.6rem',
    '4.4rem',
    '5.2rem', // 9
    '6.4rem',
  ],
  colors,
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xlg: '1200px',
  },
};
