import React, { useState } from 'react';
import { SmallText, Carousel } from '@components';

import arrowIcon from '@assets/icons/arrow-right.svg';
import iconClientAutibooks from '@assets/icons/clients-autibooks.svg';
import iconClientBoonited from '@assets/icons/clients-boonited.svg';
import iconClientClickcard from '@assets/icons/clients-clickcard.svg';
import iconClientJurishand from '@assets/icons/clients-jurishand.svg';
import iconClientNursebook from '@assets/icons/clients-nursebook.svg';

import {
  Holder,
  Content,
  RightArrow,
  CarouselItem,
  CarouselTitle,
  CustomContainer,
} from './styles';

const items = [
  { id: 0, key: 'nursebook', icon: iconClientNursebook },
  { id: 1, key: 'autibooks', icon: iconClientAutibooks },
  { id: 2, key: 'jurishand', icon: iconClientJurishand },
  { id: 3, key: 'boonited', icon: iconClientBoonited },
  { id: 4, key: 'clickcard', icon: iconClientClickcard },
  { id: 5, key: 'nursebook', icon: iconClientNursebook },
  { id: 6, key: 'autibooks', icon: iconClientAutibooks },
  { id: 7, key: 'jurishand', icon: iconClientJurishand },
  { id: 8, key: 'boonited', icon: iconClientBoonited },
  { id: 9, key: 'clickcard', icon: iconClientClickcard },
];

export const CarouselBar = () => {
  const [slideShowing, setSlideShowing] = useState(0);

  const handleChangeSlide = () =>
    setSlideShowing(slideShowing !== items.length ? slideShowing + 1 : 0);

  return (
    <CustomContainer>
      <Holder>
        <Content>
          <CarouselTitle>Clientes</CarouselTitle>
          <SmallText color="whiteMate">Parcerias de sucesso</SmallText>
        </Content>
        <RightArrow onClick={handleChangeSlide}>
          <img src={arrowIcon} alt="clients caroussel" />
        </RightArrow>
      </Holder>
      <Carousel slideIndex={slideShowing}>
        {items.map(item => (
          <CarouselItem key={item.id}>
            <img src={item.icon} alt={item.key} />
          </CarouselItem>
        ))}
      </Carousel>
    </CustomContainer>
  );
};
