import styled, { css } from 'styled-components';
import { theme } from '@theme/theme';

const handleWeightOfTtext = weight => {
  switch (weight) {
    case 'bolder':
      return 'font-weight:  800';
    case 'bold':
      return 'font-weight:  700';
    case 'semibold':
      return 'font-weight:  600';
    case 'lightly-bold':
      return 'font-weight:  500';
    default:
      return 'font-weight:  400';
  }
};

const handleTransformOfTtext = transform => {
  switch (transform) {
    case 'upper':
      return 'text-transform:  uppercase';
    default:
      return '';
  }
};

export const fontBase = css`
  ${({ weight }) => handleWeightOfTtext(weight)};
  ${({ transform }) => handleTransformOfTtext(transform)};
  text-align: ${({ align = 'left' }) => align};
  font-style: ${({ fontStyle = 'normal' }) => fontStyle};
  line-height: ${({ lineHeight = 'normal' }) => lineHeight};
  padding-bottom: ${({ bottom = 0 }) => theme.spaces[bottom]};
  padding-top: ${({ top = '0' }) => theme.spaces[top]};
  color: ${({ color = 'black' }) => theme.colors[color]};
`;

export const Text = styled.p`
  ${fontBase};
  font-size: ${theme.sizes[0]};
  @media (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.sizes[1]};
  }
  @media (min-width: ${theme.breakpoints.lg}) {
    font-size: ${theme.sizes[3]};
  }
`;

export const Paragraph = styled.p`
  ${fontBase};
  font-size: ${theme.sizes[0]};
  @media (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.sizes[1]};
  }
  @media (min-width: ${theme.breakpoints.lg}) {
    font-size: ${theme.sizes[2]};
  }
`;

export const HugeTitle = styled.h1`
  ${fontBase};
  font-size: ${theme.sizes[6]};
  @media (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.sizes[7]};
  }
  @media (min-width: ${theme.breakpoints.lg}) {
    font-size: ${theme.sizes[9]};
  }
`;

export const Title = styled.h1`
  ${fontBase};
  font-size: ${theme.sizes[6]};
  @media (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.sizes[7]};
  }
`;

export const Subtitle = styled.h1`
  ${fontBase};
  font-size: ${theme.sizes[2]};
  @media (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.sizes[3]};
  }
  @media (min-width: ${theme.breakpoints.lg}) {
    font-size: ${theme.sizes[5]};
  }
`;

export const MinorSubtitle = styled.h1`
  ${fontBase};
  font-size: ${theme.sizes[1]};
  @media (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.sizes[2]};
  }
  @media (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.sizes[3]};
  }
`;

export const SmallText = styled.p`
  ${fontBase};
  font-size: ${theme.sizes[0]};
`;

export const CustomizeText = styled.p`
  ${fontBase};
`;
