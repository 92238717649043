import React from 'react';
import { Container } from '@components';
import { Label, BulletId, BulletShadow, LimitedImage } from './styles';

export const BulletItem = ({
  title,
  direction = 'column',
  image,
  weight,
  id = '0',
  innerPadding = '0',
  outerPadding = '0',
  children,
  shadow = false,
  variant = 'lg',
}) => (
  <Container direction={direction} padding={outerPadding}>
    {image ? (
      !shadow ? (
        <img src={image} alt={title} />
      ) : (
        <BulletShadow>
          <LimitedImage src={image} alt={title} />
        </BulletShadow>
      )
    ) : (
      <BulletId>{id}</BulletId>
    )}
    <Container direction="column" padding={innerPadding}>
      <Label bottom="2" weight={weight} variant={variant}>
        {title}
      </Label>
      {children}
    </Container>
  </Container>
);
