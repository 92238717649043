import styled from 'styled-components';
import { theme } from '@theme/theme';

export const CustomSelect = styled.select`
  color: ${theme.colors.halfDarkPurple};
  opacity: 0.5;
  background: transparent;

  font-size: ${theme.sizes[1]};
  font-weight: 700;

  width: 100%;
  option {
    background-color: ${theme.colors.white};
    :hover {
      box-shadow: 0 0 10px 100px red inset;
    }
  }
  -o-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;
