import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Container, Paragraph, Button, Link } from '@components';
import { useModalData } from '@services';
import { navigate } from 'gatsby';

import iconX from '@assets/icons/icon-x.svg';
import iconSuccess from '@assets/icons/icon-success.svg';
import {
  CustomContainer,
  CloseIcon,
  CustomTitle,
  OpacityContainer,
} from './styles';

export const Modal = () => {
  const { open, handleToggleModal } = useModalData();
  const [display, setDisplay] = useState(open ? 'block' : 'none');
  const [didMount, setDidMount] = useState(false);

  const callOpacityDelayChange = () => {
    if (display === 'block') {
      setDisplay('transition');
      return setTimeout(() => {
        setDisplay('none');
      }, 250);
    }
  };

  useLayoutEffect(() => {
    if (open) {
      document.body.classList.add('hidden-body');
      setDisplay('block');
    } else {
      document.body.classList.remove('hidden-body');
      if (didMount) callOpacityDelayChange();
    }
    return () => {
      document.body.classList.remove('hidden-body');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, didMount]);

  useEffect(() => {
    setDidMount(true);
  }, []);

  return (
    <OpacityContainer display={display}>
      <CustomContainer padding={'3 3 4'} direction="column">
        <Container justify="flex-end">
          <CloseIcon src={iconX} onClick={handleToggleModal} alt="close" />
        </Container>
        <Container
          padding="0 1"
          direction="column"
          align="center"
          justify="center"
        >
          <img src={iconSuccess} alt="success" />
          <CustomTitle
            weight="bold"
            top="3"
            align="center"
            color="halfDarkPurple"
          >
            Recebemos seus dados
          </CustomTitle>
        </Container>
        <Container direction="column">
          <Paragraph top="2" bottom="2" align="center" color="darkPurple">
            Em até 4 horas, entraremos em contato com você!
          </Paragraph>

          <Button
            margin="2 0"
            fullWidth="100%"
            text="Ok"
            variant="blue"
            onClick={() => navigate('/entraremos-em-contato')}
          />
        </Container>
      </CustomContainer>
    </OpacityContainer>
  );
};
