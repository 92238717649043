import styled from 'styled-components';
import { theme } from '@theme/theme';

export const WidthModifier = styled.span`
  max-width: ${({ maxW }) => maxW || '800px'};
  text-align: center;
  margin-top: 24px;
`;

export const ColorModifier = styled.span`
  color: ${theme.colors.blue};
`;

export const OptionsRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 80vw;
  align-items: flex-start;
  justify-content: center;
`;

