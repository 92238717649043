import React from 'react';
import { Section, Container, HugeTitle, StepOption, MinorSubtitle } from '@components';
import { WidthModifier, OptionsRow } from './styles';


export const StepRender = ({ goToPreviousStep, goToNextStep, title, options, currentStep }) => {
  return  (
    <Section direction="column">
       <MinorSubtitle>{`${currentStep}/9`}</MinorSubtitle>
      <WidthModifier maxW="800px">
        <HugeTitle align="center" weight="semibold" bottom="3">
          {title}
        </HugeTitle>
      </WidthModifier>
      <Container margin="6 0 0" direction="column" align="center">
        <OptionsRow>
          {options.map(opt => (
            <StepOption
              key={opt.id}
              onClick={() => goToNextStep(opt)}
              imageSrc={opt.src}
              optionText={opt.name}
              subtitle={opt.subtitle}
            />
          ))}
        </OptionsRow>
  
      </Container>
    </Section>
  );
}
