import styled from 'styled-components';
import { CustomizeText } from '@components';
import { theme } from '@theme/theme';

export const Title = styled(CustomizeText)`
  font-size: ${theme.sizes[4]};
`;

export const FormHead = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: ${theme.spaces[4]};
  img {
    width: 20px;
    height: 20px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: ${theme.spaces[5]};
  background: ${theme.colors.white};
  max-width: ${({ maxW = 'unset' }) => maxW};
  border-radius: 5px;
`;