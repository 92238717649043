function parse(maskPattern, original) {
  let index = -1;
  let accumulator = '';
  let maskedValue = '';

  return maskPattern.split('').reduce((newValue, currentCharacter) => {
    const value = newValue;

    if (index >= original.length) {
      return value;
    }

    if (currentCharacter === '#') {
      index += 1;
      maskedValue = value;

      if (original[index]) {
        maskedValue = value + accumulator + original[index];
      }
      accumulator = '';

      return maskedValue;
    }

    accumulator += currentCharacter;

    return value;
  }, '');
}

function removeAllNonDigitCharacters(v = '') {
  return v.replace(/\D/g, '');
}

const patterns = {
  cpf: '###.###.###-##',
  cnpj: '##.###.###/###-##',
  cellphone: '(##) # ####-####',
  phone: '(##) ####-####',
  date: '##/##/####',
  cep: '#####-###',
};

export function mask(input = '', maskType) {
  const pattern = maskType ? patterns[maskType.toLowerCase()] : '';

  if (!input || !pattern) {
    return '';
  }

  return parse(pattern, String(removeAllNonDigitCharacters(input)));
}

export const getValuesFromArray = (values, mode) => {
  switch (mode) {
    case 'mobile':
      return values.length ? values[0] : '0';
    case 'desktop':
      return values.length > 1 ? values[1] : '0';
    default:
      return values.length ? values[0] : '0';
  }
};

export const handleMarginOrPadding = (values, theme, mode = 'mobile') => {
  const processedValues =
    typeof values === 'string' ? values : getValuesFromArray(values, mode);
  const splittedValues = processedValues.split(' ');
  const formattedValues = splittedValues.map(pad => theme.spaces[pad]);
  return formattedValues.join(' ');
};

export const fieldHasError = (fieldName, touched, errors) => {
  if (touched[fieldName]) {
    return !!errors[fieldName];
  }

  return false;
};
