import React from 'react';
import { Logo } from '@components';
import { Bar, LogoSection } from './styles';

export const Header = () => (
  <Bar>
    <LogoSection>
      <Logo />
    </LogoSection>
  </Bar>
);
