import styled from 'styled-components';
import { StyleContainer } from '@components';
import { theme } from '@theme/theme';

export const CustomSection = styled(StyleContainer)`
  background-color: ${({ color = 'unset' }) =>
    color !== 'unset' ? theme.colors[color] : color};
  background-position: ${({ position = '0% 0%' }) => position};
  background-repeat: no-repeat;
  background-size: ${({ size = 'auto' }) => size};
  position: ${({ relative }) => (relative ? 'relative' : 'static')};
  @media (min-width: ${theme.breakpoints.lg}) {
    background-image: url(${props => props.backgroundLg || 'unset'});
  }
`;
