import React from 'react';
import styled, { keyframes } from 'styled-components';
import { theme } from '@theme/theme';

const animation = keyframes`
 0% {transform: rotate(0deg)}
 100%{transform: rotate(360deg)}
`;

const Container = styled.div`
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    margin: 8px;
    border: 8px solid ${theme.colors.blue};
    border-radius: 50%;
    animation: ${animation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${theme.colors.blue} transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: 0.15s;
  }
`;

export const Loader = () => (
  <Container>
    <div />
    <div />
    <div />
    <div />
  </Container>
);
