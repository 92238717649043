import styled from 'styled-components';
import { theme } from '@theme/theme';

const handleError = (hasError, colors) => {
  return hasError
    ? `
      border-color: ${colors.red};
      &:hover {
        border-color: ${colors.red};
      }

      &:focus-within {
        border-color: ${colors.red};
        box-shadow: 0 0 0 1px ${colors.red};          
      }
    `
    : '';
};

export const Label = styled.label`
  transition: color 0.2s ease-in-out;
  color: #b1afc2;
  display: none;
  padding: ${`0 ${theme.spaces[1]}`};
  position: absolute;
  top: -10px;
  font-size: ${theme.sizes[1]};
  font-weight: 400;
  background: ${theme.colors.white};
`;

export const Root = styled.div`
  transition: all 0.2s ease-in-out;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  
  border: 1px solid ${theme.colors.gray};
  background-color: ${theme.colors.white};
  border-radius: 5px;

  position: relative;
  
  height: 55px;
  width: 100%;

  padding: ${theme.spaces[2]};

  margin-bottom: ${theme.spaces[4]};

  &:hover {
    border-color: ${theme.colors.blue};
    svg {
      path {
        fill: ${theme.colors.blue};
        fill-opacity: 1 !important;
        transition: all 0.2s ease-in-out;
      }
      g {
        opacity: 1;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  &:focus-within {
    border-color: ${theme.colors.blue};
    box-shadow: 0 0 0 1px ${theme.colors.blue};
    ${Label} {
      display: inline-block;
    }
    svg {
      path {
        fill: ${theme.colors.blue};
        fill-opacity: 1 !important;
        transition: all 0.2s ease-in-out;
      }
      g {
        opacity: 1;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  ${({ hasError }) => handleError(!!hasError, theme.colors)}

  font-size: ${theme.sizes[1]};
  font-weight: 700;
`;

export const LabelHolder = styled.div`
  width: 100%;
  margin-bottom: ${theme.spaces[2]};
`;

export const FieldContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: ${theme.sizes[1]};
  font-weight: 700;
  padding: ${`0 ${theme.spaces[1]}`};
`;

export const Input = styled.input`
  font-size: ${theme.sizes[1]};
  font-weight: 700;

  width: 100%;
  background: transparent;
  color: ${theme.colors.halfDarkPurple};
  opacity: 0.5;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${theme.colors.halfDarkPurple};
  }
  :-ms-input-placeholder {
    color: ${theme.colors.halfDarkPurple};
  }
`;
