import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Field, Container, Button } from '@components';
import { fieldHasError } from '@utils';
import { schema, initialValues, roleValues } from './settings';
import { Form, FormHead, Title } from './styles';
import iconArrow from '@assets/icons/arrow-down.svg';

export const ContactForm = ({ onCalculatorScreen, selectedOptions }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://d335luupugsy2.cloudfront.net/js/loader-scripts/57c882f9-7f0c-48ff-9a8b-c467fb543afd-loader.js';
    script.async = true;
    document.body.appendChild(script);
    if (selectedOptions) {
      formatSelectOptions();
    }
  }, [selectedOptions]);

  const {
    values,
    errors,
    setFieldValue,
    handleBlur,
    handleChange,
    touched,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object(schema),
    validateOnChange: false,
  });

  const onSubmitForm = async e => {
    e.preventDefault();
    await navigate(
      onCalculatorScreen ? '/estimativa-final-app' : '/entraremos-em-contato'
    );
  };

  const formatSelectOptions = () => {
    let optionsString = '';
    selectedOptions.map(opt => {
      if (opt.finalValue) {
        optionsString = `${optionsString} ----- VALOR FINAL: R$${opt.finalValue}`;
      } else {
        optionsString = `${optionsString} ///// ${opt.name} : R$${opt.value}`;
      }
    });
    setFieldValue('options', optionsString);
  };

  // action={onCalculatorScreen ? '/estimativa-app' : '/entraremos-em-contato'}

  return (
    <Container
      style={{ zIndex: 1 }}
      maxW="400px"
      margin={['8 0 0', '0']}
      direction="column"
      align="center"
    >
      <FormHead>
        <Title color="white" weight="bold" bottom="2" align="center">
          {onCalculatorScreen
            ? 'Falta apenas 1 passo para descobrir o valor do seu app, preencha o formulário abaixo e descubra!'
            : 'Preencha o formulário'}
        </Title>
        <img src={iconArrow} alt="Preencha o formulário" />
      </FormHead>
      <Form onSubmit={onSubmitForm}>
        <Field
          placeholder="Nome"
          name="name"
          label="Nome"
          icon="user"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.name}
          hasError={fieldHasError('name', touched, errors)}
          validationMessage={errors.name}
        />
        <Field
          placeholder="Email"
          name="email"
          label="Email"
          type="email"
          icon="at"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.email}
          hasError={fieldHasError('email', touched, errors)}
          validationMessage={errors.email}
        />
        <Field
          mask="cellphone"
          placeholder="Whatsapp"
          name="whatsapp"
          label="Whatsapp"
          icon="phone"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.whatsapp}
          hasError={fieldHasError('whatsapp', touched, errors)}
          validationMessage={errors.whatsapp}
        />
        <Field
          placeholder="Cargo"
          name="role"
          type="select"
          label="Cargo"
          icon="briefcase"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.role}
          hasError={fieldHasError('role', touched, errors)}
          validationMessage={errors.role}
          options={roleValues}
        />
        <Field
          placeholder="Empresa"
          name="company"
          label="Empresa"
          icon="company"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.company}
          hasError={fieldHasError('company', touched, errors)}
          validationMessage={errors.company}
        />
        {selectedOptions &&
          selectedOptions.map((opt, index) => (
            <Field
              key={opt.id}
              name={`option${index + 1}`}
              label={`option${index + 1}`}
              disabled={true}
              value={
                opt.finalValue
                  ? `Valor Final - R$${opt.finalValue}`
                  : `${opt.name} - R$${opt.value}`
              }
              style={{ opacity: 0, height: 0, padding: 0, margin: 0 }}
            />
          ))}

        <Button
          fullWidth="100%"
          text={onCalculatorScreen ? "Quero saber o valor do meu App!" : "Falar com o especialista"}
          variant="blue"
          type="submit"
        />
      </Form>
    </Container>
  );
};
