export const colors = {
  white: '#fff',
  whiteMate: '#BBBAC4',
  purple: '#9284DC',
  yellow: '#FFD159',
  halfDarkPurple: '#2C294D',
  darkPurple: '#1C193A',
  orange: '#FF8C40',
  blue: '#009FAD',
  gray: '#D8D7E1',
  red: '#FF3333',
};
