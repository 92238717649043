import React from 'react';
import { CustomSelect } from './styles';

export const Select = ({ options, name, onChange, value, placeholder }) => {
  const getValue = item => item.value || item.label;
  return (
    <CustomSelect id={name} name={name} onChange={onChange} value={value}>
      <option value="" style={{ display: 'none' }} disabled>
        {placeholder}
      </option>
      {options.map(item => (
        <option key={getValue(item)} value={getValue(item)}>
          {item.label}
        </option>
      ))}
    </CustomSelect>
  );
};
