import React, { useState, createContext, useContext } from 'react';

export const ModalCtx = createContext({});

export const useModalData = () => useContext(ModalCtx);

export const ModalProvider = ({ children }) => {
  const [open, setOpen] = useState(false);

  const handleToggleModal = () => {
    setOpen(!open);
  };

  const valueProvider = {
    open,
    handleToggleModal,
  };

  return (
    <ModalCtx.Provider value={valueProvider}>{children}</ModalCtx.Provider>
  );
};
