import styled from 'styled-components';
import { CustomizeText } from '../Fonts';
import { theme } from '@theme/theme';

const getSizeOfVariant = variant => {
  switch (variant) {
    case 'sm':
      return theme.sizes[0];
    case 'md':
      return theme.sizes[3];
    default:
      return theme.sizes[4];
  }
};

export const Label = styled(CustomizeText)`
  font-size: ${({ variant }) => getSizeOfVariant(variant)};
`;

export const LimitedImage = styled.img`
  width: 24px;
  height: 24px;
`;

export const BulletShadow = styled.div`
  background: #302e52;
  border-radius: 8px;
  padding: ${theme.spaces[2]} ${theme.spaces[3]};
`;

export const BulletId = styled(BulletShadow)`
  color: white;
  font-weight: bold;
  font-size: ${theme.sizes[5]};
  padding: ${theme.spaces[2]} ${theme.spaces[3]};
`;
