import styled from 'styled-components';
import { Title } from '@components';
import { theme } from '@theme/theme';

export const ScoreText = styled(Title)`
  color: ${theme.colors.yellow};
  font-weight: 600;
  padding-left: ${theme.spaces[2]};
  @media (min-width: ${theme.breakpoints.lg}) {
    font-size: ${theme.sizes[8]};
  }
`;

export const AppImg = styled.img`
  width: 286px;
  height: 392px;
`;

export const Holder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 175px;
`;

export const LogoContainer = styled.div`
  height: 61px;
`;
