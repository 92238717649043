import React from 'react';
import { CustomSection } from './styles';

const handlePaddingForSection = padding => {
  switch (padding) {
    case 'regular':
      return ['6 4 6', '7 6 3'];
    default:
      return padding;
  }
};

export const Section = ({
  children,
  color,
  background,
  backgroundLg,
  full,
  direction,
  position,
  size,
  justify = 'flex-start',
  align = 'center',
  relative = false,
  responsiveDirection,
  padding = ['7 4 5', '7 6 5'],
}) => (
  <CustomSection
    {...{
      full,
      responsiveDirection,
      position,
      size,
      color,
      justify,
      relative,
      background,
      backgroundLg,
      direction,
      padding: handlePaddingForSection(padding),
      align,
      variant: 'main',
    }}
  >
    {children}
  </CustomSection>
);
