import * as Yup from 'yup';

export const schema = {
  email: Yup.string()
    .required('Informe um endereço de email')
    .email('Informe um endereço válido de email'),
  name: Yup.string().required('Informe o nome'),
  whatsapp: Yup.string().required('Informe o telefone'),
  role: Yup.string().required('Informe o cargo'),
  company: Yup.string().required('Informe a empresa'),
  options: Yup.string()
};

export const initialValues = {
  name: '',
  email: '',
  whatsapp: '',
  role: '',
  company: '',
  options: ''
};

export const roleValues = [
  {
    label: 'CEO',
    value: 'ceo',
  },
  {
    label: 'CTO',
    value: 'cto',
  },
  {
    label: 'Sócio',
    value: 'socio',
  },
  {
    label: 'Diretor',
    value: 'diretor',
  },
  {
    label: 'Gerente',
    value: 'gerente',
  },
  {
    label: 'Outros',
    value: 'outros',
  },
];
