import styled, { css } from 'styled-components';
import { handleMarginOrPadding } from '@utils';
import { theme } from '@theme/theme';

const handleVariant = variant => {
  switch (variant) {
    case 'blue':
      return css`
        background: ${theme.colors.blue};
        color: ${theme.colors.white};
      `;
    default:
      return css`
        background: ${theme.colors.orange};
        color: ${theme.colors.white};
      `;
  }
};

export const ButtonContainer = styled.button`
  ${({ variant }) => handleVariant(variant)};
  border-radius: ${theme.spaces[2]};
  width: ${({ width = 'fit-content', fullWidth }) =>
    fullWidth ? fullWidth : width};
  min-width: 150px;
  min-height: 60px;
  font-weight: bold;
  height: ${({ height }) => height || '60px'};
  padding: ${theme.spaces[2]} ${theme.spaces[5]};
  margin: ${({ margin = '0' }) => handleMarginOrPadding(margin, theme)};
  font-size: ${theme.sizes[1]};
  cursor: pointer;
`;
