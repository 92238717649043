import React from 'react';
import styled, { css } from 'styled-components';
import { handleMarginOrPadding } from '@utils';
import { theme } from '@theme/theme';

const containerBase = css`
  display: flex;
  max-width: ${({ maxW = 'unset', variant }) =>
    variant === 'main' ? '1800px' : maxW};
  width: 100%;
  flex-direction: ${({ direction = 'row' }) => direction};
  justify-content: ${({ justify = 'flex-start' }) => justify};
  align-items: ${({ align = 'flex-start' }) => align};
  padding: ${({ padding = '0' }) => handleMarginOrPadding(padding, theme)};
  margin: ${({ margin = '0' }) => handleMarginOrPadding(margin, theme)};

  @media (min-width: ${theme.breakpoints.lg}) {
    padding: ${({ padding = '0' }) =>
      handleMarginOrPadding(padding, theme, 'desktop')};
    margin: ${({ margin = '0' }) =>
      handleMarginOrPadding(margin, theme, 'desktop')};
  }
`;

export const StyleContainer = styled.div`
  ${containerBase};
  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `};
  @media (min-width: ${theme.breakpoints.lg}) {
    ${({ responsiveDirection, direction }) =>
      responsiveDirection &&
      css`
        flex-direction: ${direction === 'row' ? 'column' : 'row'};
      `}
    ${({ full }) =>
      full &&
      css`
        min-height: 100vh;
      `};
  }
`;

export const MobileOnlyContainer = styled.div`
  ${containerBase};
  @media (min-width: ${theme.breakpoints.lg}) {
    display: none;
  }
`;

export const DesktopOnlyContainer = styled.div`
  display: none;
  flex-direction: ${({ direction = 'row' }) => direction};
  @media (min-width: ${theme.breakpoints.lg}) {
    display: flex;
  }
`;

export const Container = props => (
  <StyleContainer {...props}>{props.children}</StyleContainer>
);
