import React from 'react';
import { Subtitle, SmallText } from '../../atoms'
import { OptionContainer, OptionImage } from './styles';

export const StepOption = ({ imageSrc, optionText, subtitle, onClick }) => {
  return (
    <OptionContainer onClick={onClick}>
      <OptionImage src={imageSrc} />
      <Subtitle  align="center">{optionText}</Subtitle>
      <SmallText top={2} align="center">{subtitle}</SmallText>
    </OptionContainer>
  )
}