import React from 'react';
import Carousel from 'nuka-carousel';

export const defaultSettings = {
  cellSpacing: 0,
  slidesToScroll: 'auto',
  slideWidth: '175px',
  slideWidthMedium: '0px',
  wrapAround: true,
  withoutControls: true,
  dragging: false,
};

export const CustomCarousel = ({
  children,
  slideIndex = 0,
  settings = defaultSettings,
}) => {
  return (
    <Carousel {...settings} slideIndex={slideIndex}>
      {children}
    </Carousel>
  );
};
