import React from 'react';
import { Container, Paragraph } from '@components';

import starIcon from '@assets/icons/star.svg';

import { LogoContainer, ScoreText, AppImg, Holder } from './styles';

export const PhoneItem = ({ logo, id, score, app }) => (
  <Container margin={['4 0', '0']} direction="column" align="center">
    <Holder>
      <LogoContainer>
        <img src={logo} alt={id} />
      </LogoContainer>
      <Paragraph top="3" bottom="3" weight="bold">
        Avaliação média nas stores
      </Paragraph>
      <Container justify="center" margin="0 0 3" align="center">
        <img src={starIcon} alt={score} />
        <ScoreText>{score}</ScoreText>
      </Container>
    </Holder>
    <AppImg src={app} />
  </Container>
);
