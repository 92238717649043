import styled, { keyframes, css } from 'styled-components';
import { StyleContainer, CustomizeText } from '@components';
import { theme } from '@theme/theme';

const reveal = keyframes`
  from {
    display: block;
    background: rgba(0,0,0,0);
  }

  to {
    background: rgba(0,0,0,0.5);
  }
`;
const hide = keyframes`
  from: {
    background: rgba(0,0,0,0.5);
  }
  to {
    background: rgba(0,0,0,0);
  }
`;
const handleDisplay = display => {
  switch (display) {
    case 'block':
      return css`
        animation-name: ${reveal};
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
      `;
    case 'transition':
      return css`
        animation-name: ${hide};
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
      `;
    case 'none':
      return css`
        display: none;
      `;
    default:
      return null;
  }
};

export const CloseIcon = styled.img`
  cursor: pointer;
`;

export const CustomContainer = styled(StyleContainer)`
  background: ${theme.colors.white};
  border-radius: 16px;
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 6;
  width: 320px;
`;

export const OpacityContainer = styled.div`
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0px;
  z-index: 5;
  ${({ display }) => handleDisplay(display)}
`;

export const CustomTitle = styled(CustomizeText)`
  font-size: ${theme.sizes[4]};
  @media (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.sizes[5]};
  }
  @media (min-width: ${theme.breakpoints.lg}) {
    font-size: ${theme.sizes[6]};
  }
`;
