import styled from 'styled-components';
import { CustomizeText, StyleContainer } from '@components';
import { theme } from '@theme/theme';

export const Holder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1f1d3f;
  height: 100%;
  width: 250px;
  position: relative;
`;

export const CustomContainer = styled(StyleContainer)`
  width: 100%;
  height: 160px;
`;

export const Content = styled.div`
  max-width: 100px;
  @media (min-width: ${theme.breakpoints.sm}) {
    max-width: unset;
  }
`;

export const CarouselItem = styled.div`
  background: #1b193a;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
  width: 175px;
`;

export const RightArrow = styled.div`
  background: #009fad;
  padding: 5px 10px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  right: -10px;
  opacity: 0.5;
  z-index: 1;
  :hover {
    opacity: 1;
  }
`;

export const CarouselTitle = styled(CustomizeText)`
  font-weight: 700;
  font-size: ${theme.sizes[5]};
  @media (min-width: ${theme.breakpoints.sm}) {
    font-size: ${theme.sizes[6]};
  }
  @media (min-width: ${theme.breakpoints.lg}) {
    font-size: ${theme.sizes[7]};
  }
`;
