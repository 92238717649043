import styled from 'styled-components';
import { theme } from '@theme/theme';

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 260px;
  border-radius: 20px;
  padding: 12px;
  width: 220px;
  cursor: pointer;
  border: 3px solid transparent;

  &:hover {
    opacity: 0.5;
    border: 3px solid ;
    border-color: ${theme.colors.orange};
  }
`;

export const OptionImage = styled.img`
  height: 100px;
  width: auto;
  margin-bottom: 24px;
  cursor: pointer;
`;