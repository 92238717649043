import React, { useRef } from 'react';
import { Select } from '@components';

import { mask } from '@utils';
import {
  iconPhone,
  iconAt,
  iconUser,
  iconCompany,
  iconBriefcase,
} from './icons';

import { FieldContent, Input, Label, Root } from './styles';

export const Field = ({ label, icon, placeholder, type, ...rest }) => {
  const ref = useRef(null);
  const inputProps = {
    type: 'text',
    ...rest,
  };

  if (rest.mask) {
    inputProps.onChange = event => {
      event.target.value = mask(event.target.value, rest.mask);

      return rest.onChange && rest.onChange(event);
    };
  }

  const renderIcon = () => {
    switch (icon) {
      case 'user':
        return iconUser();
      case 'phone':
        return iconPhone();
      case 'at':
        return iconAt();
      case 'company':
        return iconCompany();
      case 'briefcase':
        return iconBriefcase();
      default:
        return null;
    }
  };

  return (
    <Root {...inputProps}>
      <Label>{label}</Label>
      <FieldContent>
        {type === 'select' ? (
          <Select {...rest} placeholder={placeholder} />
        ) : (
          <Input
            required
            className="field-input"
            ref={ref}
            {...inputProps}
            placeholder={placeholder}
          />
        )}
        {renderIcon()}
      </FieldContent>
    </Root>
  );
};
