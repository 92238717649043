import React from 'react';
import PropTypes from 'prop-types';
import { Header, Container, Modal } from '@components';
import { ModalProvider } from '@services';

export const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <ModalProvider>
        <Container justify="center">
          <main> {children} </main>
        </Container>
        <Modal />
      </ModalProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
