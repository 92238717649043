import React from 'react';
import { ButtonContainer } from './styles';

export const Button = ({
  text,
  width,
  fullWidth,
  type = 'button',
  variant = 'default',
  margin,
  height,
  onClick = () => {},
}) => (
  <ButtonContainer
    type={type}
    margin={margin}
    fullWidth={fullWidth}
    variant={variant}
    width={width}
    height={height}
    onClick={onClick}
  >
    {text}
  </ButtonContainer>
);
